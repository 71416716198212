import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public jobObj = {
    //jobId: null,
    //jobTitle: null,
    //parentJobMediaId: null,
    //parentJobMediaName: null,
/*    selectedEntityId: 0,*/
    entityId: null,
    entityTitle: "",
    parentEntityId: null,
    parentEntityName: null,
    possibleToUploadType: null,
  };
  public downloadObj: any[];
  public quickHelp = { referralCode: null };

  subject: Subject<any> = new Subject<any>();
  subjectQuickHelp: Subject<any> = new Subject<any>();


  changeUploadSideBarJobSelection(newJobObj: {
    //jobId: null,
    //jobTitle: "",
    //parentJobMediaId: null,
    //parentJobMediaName: null,
    possibleToUploadType: null,
  /*  selectedEntityId: null,*/
    entityId: null,
    entityTitle: "",
    parentEntityId: null,
    parentEntityName: null;
  }) {
    this.jobObj = newJobObj;
    this.subject.next(this.jobObj);
  }

  changeDownloadSelection(newDownloadObj: any[]) {
    this.downloadObj = newDownloadObj;
    this.subject.next(this.downloadObj);
  }

  changeQuickHelpSelection(obj: { referralCode: null}) {
    this.quickHelp = obj;
    this.subjectQuickHelp.next(this.quickHelp);
  }
}
