import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppDataService } from '../../util/appdataservice';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
    constructor(private authenticationService: AppDataService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.authenticationService.logout();
            }
            else if (err.status === 403) {
                return throwError('NO_AUTHORIZATION');
            }
            else if (err.status === 419) {
                this.authenticationService.logout();
            }
            else
                return throwError(err.statusText);  //const error =  err.error.message || err.statusText;
        }))
    }
}
