import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private actionUrl: string;

    constructor() {
        const data = require("../../../../../appsettings.json");
        this.actionUrl = data.Settings.ApiUrl;
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let currentToken = JSON.parse(localStorage.getItem('currentToken'));
        if (currentToken) {
            if (req.url.startsWith(this.actionUrl, 0) && !req.url.startsWith('http://127.0.0.1', 0)) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentToken}`
                    }
                });
            }
        }

        return next.handle(req).pipe(tap(event => {
            if (event instanceof HttpResponse) {
            }
        }));

    }
}


