<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['pages/home/home-dashboard']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> 
      </a>
    </li>
  </ul>
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

    </div>


    <!--{{ _translateService.currentLang}}-->
<ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
        <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
            <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="dropdown-flag">
            <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
            </a>
        </div>
    </li>
    <!--/ Language selection -->


    <!-- Toggle skin -->
    <!--<li class="nav-item d-none d-lg-block">
        <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
            <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
        </a>
    </li>-->
    <!--/ Toggle skin -->


    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
        <a class="nav-link dropdown-toggle dropdown-user-link"
           id="dropdown-user"
           ngbDropdownToggle
           id="navbarUserDropdown"
           aria-haspopup="true"
           aria-expanded="false">
            <ng-container *ngIf="this.currentUser">
                <div class="user-nav d-sm-flex d-none">
                  
                    <span class="user-name font-weight-bolder">{{ this.currentUser.adi }} {{ this.currentUser.soyadi }}</span><span class="user-status"></span>
                </div>
                <span class="avatar">
                    <img class="round"
                         src="{{ this.currentUser.profilResmiDosyaYolu }}"
                         alt="avatar"
                         height="40"
                         width="40" /><span class="avatar-status-online"></span>
                </span>
            </ng-container>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem (click)="navBarChangePassword()"><span [data-feather]="'lock'" [class]="'mr-50'"></span> {{ 'NavBarSifreDegistir' | translate }}</a>
            <a ngbDropdownItem (click)="navBarLogout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> {{ 'NavBarCikis' | translate }}</a>
        </div>
    </li>
    <!--/ User Dropdown -->
</ul>
</div>
