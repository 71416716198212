import { Role } from './role';

export class User {
  //id: number;
    oid: string;
  email: string;
  //password: string;
  adi: string;
    soyadi: string;
    profilResmiDosyaYolu: string;
/*  avatar: string;*/
  role: Role;
  token?: string;
}
