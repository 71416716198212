import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var helper = new JwtHelperService();
        var token = localStorage.getItem('currentToken');
        var tokenBool = true;
        const expirationDate = jwtHelper.getTokenExpirationDate(token);
        const isExpired = jwtHelper.isTokenExpired(token);
        if (localStorage.getItem('currentToken') && !isExpired) {
            return true;
        }
        this.router.navigate(['/authentication/authentication/login'], { queryParams: { return: 'SESSION_LOST' } });
        return false;

    }
}
