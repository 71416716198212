import { Component, OnInit, OnDestroy, ElementRef, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppDataService } from '../../../app/util/appdataservice';
import { CoreSharedDataService } from "../../services/shared-data.service";
import { Guid } from "guid-typescript";
//import { AppUtil } from '../../../app/util/AppUtil';
import {
    HttpClient,
    HttpRequest,
    HttpEvent,
    HttpEventType, HttpHeaders
} from '@angular/common/http'
//import { AzureUploadType } from "../../utility/utility-constant";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
//import { ToasterService } from "angular2-toaster";
//import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from "ngx-file-drop";
import { FormGroup, FormBuilder } from "@angular/forms";
//import { MediaPermissionService } from "../../core/media-permission/media-permission.service";
//import { SettingsService } from "../../core/settings/settings.service";
import { LayoutService } from "../../../app/layout/layout.service";
import { localeOrtak as enOrtak } from '../../../assets/i18n/en';
import { localeOrtak as trOrtak } from '../../../assets/i18n/tr';
import { CoreTranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from "../../../app/utility/utility";
import { saveAs } from 'file-saver';
import { CoreSidebarService } from "../core-sidebar/core-sidebar.service";
import { jsDocComment } from "@angular/compiler";
//const swal = require("sweetalert");


@Component({
    selector: 'core-uploadsidebar',
    templateUrl: './uploadsidebar.component.html',
    styleUrls: ['./uploadsidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoreUploadSidebarComponent implements OnInit, OnDestroy {

    @Input()
    progress;

    @ViewChild("fileUpload", { static: false })
    fileUpload: ElementRef;

    // TOASTER
    toaster: any;
    toasterConfig: any;

    // #region UPLOADER
    /*  files: NgxFileDropEntry[] = [];*/
    response: any;
    mySubscriptionDownload: any;
    dataappsettings: any;
    progressIndex = -1;
    progressIndexDownload = -1;

    //response: any;
    responseCount: any = 0;
    totalFileSizeDownload: any = 0;
    totalFileSizeDownloadWithUnit: any = "";
    totalFileSizeDownloaded: any = 0;
    totalFileSizeDownloadedWithUnit: any = "";
    percentDone: any = 0;
    requestDownloadArray: any[];

    constructor(
        private _coreTranslationService: CoreTranslationService,
        private _translateService: TranslateService,
        public elem: ElementRef,
        private sharedDataService: CoreSharedDataService,
        private http: HttpClient,
        fb: FormBuilder,
        private _appDataService: AppDataService,
        private layoutService: LayoutService,
        private _coreSidebarService: CoreSidebarService) {
        this._coreTranslationService.translate(enOrtak, trOrtak);
    }

    ngOnInit() {

        this.mySubscriptionDownload = this.layoutService.subject.subscribe((newDownloadObj: any[]) => {
            if (newDownloadObj.length > 0) {


           

                this.requestDownloadArray = newDownloadObj;

                if (!this._coreSidebarService.getSidebarRegistry('uploadCustomizer').isOpened) {
                    this.toggleSidebar('uploadCustomizer');
                }
                console.log(this.requestDownloadArray);
                return this._appDataService.postFuncP('Ortak/GetMediaFileLink', this.requestDownloadArray[0]).toPromise()
                    .then((data: any) => {
                        if (data.resultType == 2 || data.resultType == 0) {
                        }
                        else {
                            var fileList = [];
                            fileList.push(data.innerData);
                            this.downloadProcess(fileList);
                        }
                    }).catch(error => { console.error('Error downloading file:', error); });
            }
        });
    }

    checkCloseUploadsidebar = e => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            //this.settings.setLayoutSetting("uploadsidebarOpen", false);
        }
    };

    ngOnDestroy() {
        document.removeEventListener("click", this.checkCloseUploadsidebar);
        this.mySubscriptionDownload.unsubscribe();
    }


    private units = [
        "bytes",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB"
    ];

    transform(bytes: any = 0, precision: number = 1): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return "?";

        let unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        return bytes.toFixed(+ precision) + " " + this.units[unit];
    }

    callFuncPost(req) {
        this.progressIndex = this.progressIndex + 1;
        return this.http.request(req);
    }

  


    //#region Download Process

    downloadProcess(fileList): void {



        var progresTemp = 0;
        var requestArray = [];
        var requestItem = { mediaFileName: "", mediaUri: "", mediaSize: 0, downloaded: 0 };
        var downloadedFileCount = 0;

        for (let i = 0; i < fileList.length; i++) {
                requestItem = { mediaFileName: "", mediaUri: "", mediaSize: 0, downloaded: 0 };
                requestItem.mediaFileName = fileList[i].mediaFileName;
                requestItem.mediaUri = fileList[i].mediaUri;
                requestItem.downloaded = 0;
                requestItem.mediaSize = fileList[i].mediaSize;
                requestArray.push(requestItem);
        }

        this.totalFileSizeDownload = requestArray.reduce((total, media) => total + media.mediaSize, 0);
        this.totalFileSizeDownloadWithUnit = this.transform(this.totalFileSizeDownload);


        console.log(requestArray);

        from(requestArray)
            .pipe(
                mergeMap(obj => this.callFuncDownload(obj), 1),
        ).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                    case HttpEventType.Sent:
                        break;
                    case HttpEventType.ResponseHeader:
                        break;
                    case HttpEventType.DownloadProgress:
                        console.log('event.type');
                    console.log(event.type);
                    console.log(this.progressIndexDownload);
                        requestItem = requestArray[this.progressIndexDownload];
                        requestArray[this.progressIndexDownload].downloaded = event.loaded;
                        this.totalFileSizeDownloaded = requestArray.filter((item) => item.downloaded).map((item) => +item.downloaded)
                            .reduce((sum, current) => sum + current);
                        this.totalFileSizeDownloadedWithUnit = this.transform(this.totalFileSizeDownloaded);
                        progresTemp = Math.round(100 * this.totalFileSizeDownloaded / this.totalFileSizeDownload);
                        this.progress = progresTemp;
                        this.sharedDataService.setDataProgressCheck(true, null);
                        break;
                    case HttpEventType.Response:
                        const mediaName = requestArray.filter(x => x.mediaUri.split(" ").join("%20") == event.url)
                            .map(x => x.mediaFileName).toString();
                        saveAs(event.body, mediaName);
                        this.progress = progresTemp;
                        this.sharedDataService.setDataProgressCheck(false, null);
                        downloadedFileCount = downloadedFileCount + 1;
                        if (requestArray.length == downloadedFileCount) {
                            this.closeSidebar();
                            this.totalFileSizeDownload = 0;
                            this.totalFileSizeDownloadWithUnit = this.transform(0);
                            this.totalFileSizeDownloadedWithUnit = this.transform(0);
                            requestArray.length = 0;
                            this.progressIndexDownload = -1;
                            this.progress = 0;
                        }
                    break;
                }
            });
    }

    callFuncDownload(request) {

        console.clear();
        console.log(request);


        this.progressIndexDownload = this.progressIndexDownload + 1;
        let headers = new HttpHeaders();
        headers = headers.append('fname', request.mediaFileName);
        const req = new HttpRequest('GET', request.mediaUri, { reportProgress: true, responseType: 'blob', headers: headers });
        return this.http.request(req);
    }

    //#endregion


    closeSidebar() {
        if (this._coreSidebarService.getSidebarRegistry('uploadCustomizer').isOpened) {
            this.toggleSidebar('uploadCustomizer');
        }
    }

    /**
 * Toggle sidebar open
 *
 * @param key
 */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }


}
