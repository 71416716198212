export const localeOrtak = {
    lang: 'tr',
    data: {
        TarihRange:"Biti Tarihi Başlangıç Tarihinden sonra olamlıdır",
        Download: "İndir",
        AktifMi:"Aktif / Pasif",
        Listele: "Arama Sonuçları",
        AramaKriterleri: "Arama Kriterleri",
        YeniKayit: "Yeni Kayıt",
        Duzenle: "Düzenle",
        Kaydet: "Kaydet",
        Sil: "Sil",
        Ara: "Ara",
        Temizle: "Temizle",
        Vazgec: "Vazgeç",
        Liste: "Liste",
        LutfenBekleyiniz: "Bekleyiniz",
        Uyari: "Uyarı",
        KayitSilmeOnaymesaji: "Silme işlemi geri alınamaz,  işleme devam etmek istediğinizden emin misiniz?",
        Onayla: "Onayla",
        Bilgi: "Bilgi",
        Durum: "Durum",
        IslemGerceklesti: "İşlem Gerçekleşti.",
        REQ: "Zorunlu Alan",
        TelefonFormat:" Telefon Format +90 (000) 000-00000 şeklinde olmalıdır",
        NO_AUTHENTICATION: "Kimlik doğrulama gerekli",
        OK: "İşlem başarılı",
        FAIL: "Bir sorunla karşılaşıldı",
        SESSION_LOST: "Oturum Sonlandı",
        DATA_ALREADY_EXIST: "Mevcut Veri",
        WRONG_DATA_TYPE: "Yanlış Veri Tipi",
        TOKEN_NOT_VALID: "Geçersiz Anahtar",
        LOGIN: "Giriş",
        NO_AUTHORIZATION: "Yetkisiz İşlem",
        NO_AUTHORIZATION_FOR_THIS_USER: "Bu işlemi yapabilmek için yetkiniz yok",
        NO_VALID_RECENT_PASSWORD: "Eski Şifreniz Geçerli Değil",
        SadeceHarf: "Sadece harf girin",
        SadeceRakam: "Sadece rakam girin",
        GecerliSifre: "En az bir büyük harf, en az bir sayı ve en az 8 karakterden oluşan şifre giriniz",
        Zorunlu: "Zorunlu alan",
        GecerliTarih: "Geçerli tarih girin",
        GecerliTarihFormat: "Tarih ilgili biçime sahip olmalıdır: MM/dd/yyyy",
        GecerliEmail: "Geçerli email girin",
        PasifKayitlar: "Pasik Kayıtları Göster",
        RaporAl: "Rapor",
        Yukle: "Yükle",
        ResimSil: "Sil",
        TumSektorler: "Tüm Sektörler",
        SektorSeciniz: "Sektör Seçiniz",
        Evet: "Evet",
        Hayir: "Hayır",
        CikisMesaji: "Sistemden çıkış yapmak üzeresiniz, işleme devam etmek istiyor musunuz?",
        CikisYap: "Çıkış Yap !",
        OturumUzatmaMesajUst: "Oturumunuzun süresi dolmak üzere!",
        OturumUzatmaMesajAlt: "Aktif kalmaya devam etmek isterseniz, lütfen oturumunuzu uzatmak için 'Devam Et' butonuna tıklayın",
        OturumDevam: "Devam Et",
        OturumCikis: "Kapat",
        OturumMesaj: "Kalan Süre",
        OturumKapatiliyor: "Oturum Kapatılıyor",
        OturumZamanAsimiSayac: " saniye sonra zaman aşımına uğrayacaksınız. Oturumunuz Kapatılacak.",
        NavBarCikis: "Çıkış",
        NavBarSifreDegistir: "Şifre Değiştir",
        LinkOlustur: "Link Oluştur",
        PasifKayitlarMesaj: "Silinen Kayıtlar Görüntüleniyor! Aktife almak istediğiniz kaydı düzenle butonuna tıklayarak yeniden aktif edebilirsiniz.",
        GecerliIban:"Geçerli bir Iban giriniz"
    }
}


export const localeMenu = {
    lang: 'tr',
    data: {
        //MENU: {
        //    APPS: {
        //        SECTION: 'Apps & Pages',
        //        LOGOUT: 'Çıkış',
        //        DASHBOARD: 'Dashboard',
        //        ENVANTER:
        //        {
        //            VERISAHIBI: 'Data Subject',
        //            KISISELVERI: 'Personal Data Processed',
        //            KISISELVERIKATEGORISI: 'Category Of Personal Data',
        //            ISLEYENBIRIM: 'Role',
        //            ISSURECI: "Activity Name",
        //            ISLEMEAMACI: "Purpose Of Processing",
        //            HUKUKIDAYANAK: 'Basis Of The Activity',
        //            VERIGIRISKANALI: "Data Input Channel",
        //            TEKNIKTEDBIR: "Technical Security Measures",
        //            IDARITEDBIR: "Administrative Security Measures",
        //            SAKLAMAPERIYODU: "Time limits for erasue of data",
        //            SAKLAMAYERI: "Storage Location",
        //            ENVANTERTANIM: 'İnventory Definitions',
        //            AKTARILANKISIGRUBU: 'Recipients',
        //            VERIAKTARIMAMACI: "Purpose of Transfer",
        //            KISISELVERIKATEGORISIDOKUMANMADDE: 'Document Article',
        //            HUKUKAUYGUNLUKSEBEBI:"Hukuka Uygunluk Sebebi",
        //            DETAILLIST:"Detail List",
        //            VERIGUVENLIGI: "Data Security ",
        //            IMHASEBEBI: "Reason for Destruction",
        //            IMHASURESI: "Destruction Time",
        //            IMHAYONTEMI: "Destruction Method",
        //            COLLAPSIBLE: 'Inventory',
        //            LIST: 'List',
        //            NEW: 'New',
        //            ACCESSLIST: 'Access List'
        //        },
        //        ACCOUNT: {
        //            COLLAPSIBLE: 'Account',
        //            LIST: 'List',
        //            NEW: 'New',
        //            ACCESSLIST: 'Access List'
        //        },
        //        ORGANIZASYON: {
        //            COLLAPSIBLE: 'Organization',
        //            LIST: 'List',
        //            NEW: 'New',
        //            ACCESSLIST: 'Access List'
        //        },
        //        PERSONEL: {
        //            COLLAPSIBLE: 'Employee',
        //            LIST: 'List',
        //            NEW: 'New',
        //            ACCESSLIST: 'Employee List'
        //        },
        //    }
        //}

        MENU: {
            APPS: {
                SECTION: 'Apps & Pages',
                LOGOUT: 'Çıkış',
                DASHBOARD: 'Ana Sayfa',
                PERSONELDASHBOARD: 'Bilgilerim',
                UYUMLULUKIZLEME: "Uyumluluk İzleme",
                ACTIVITYLOG: "Etkinlik Günlüğü",
                DOCUMENTLIST: "Evrak Listesi",
                ENVANTER:
                {
                    ENVANTERTANIM: 'Envanter Tanımları',
                    VERISAHIBI: 'Veri Sahibi',
                    KISISELVERI: 'Kişisel Veri',
                    KISISELVERIKATEGORISI: 'Kişisel Veri Kategorisi',
                    ISLEYENBIRIM: 'İşleyen birim',
                    ISLEMEAMACI: "İşleme Amacı",
                    HUKUKIDAYANAK: 'Hukuki Dayanak',
                    VERIGIRISKANALI: "Veri Giriş Kanalı",
                    TEKNIKTEDBIR: "Teknik Tedbir",
                    IDARITEDBIR: "İdari Tebir",
                    SAKLAMAPERIYODU: "Saklama Periyodu",
                    SAKLAMAYERI: "Saklama Yeri",
                    AKTARILANKISIGRUBU: 'Aktarılan Kişi Grubu',
                    VERIAKTARIMAMACI: "Veri Aktarım Amacı",
                    HUKUKAUYGUNLUKSEBEBI: "Hukuka Uygunluk Sebebi",
                    DETAILLIST: "Detaylı Liste",
                    VERIGUVENLIGI: "Veri Güvenliği",
                    IMHASEBEBI: "İmha Sebebi",
                    IMHAYONTEMI: "İmha yöntemi",
                    COLLAPSIBLE: 'Envanter',
                    SABLONLIST: "Şablon Listesi",
                    SABLON: "Şablon Kaydet",
                    LIST: 'Envanter Listesi',
                    NEW: 'Yeni Envanter',
                    ACCESSLIST: 'Liste'
                },
                DOKUMANSET: {
                    DOKUMANSET: 'Doküman Set',
                    LIST: 'Liste',
                    NEW: 'Yeni',
                    ACCESSLIST: 'Doküman Set Listele'
                },
                EGITIMSET: {
                    EGITIMSET: 'Eğitim Set',
                    LIST: 'Liste',
                    NEW: 'Yeni'
                },
                RAPOR: {
                    LIST: 'Raporlar',
                },
                ACCOUNT: {
                    COLLAPSIBLE: 'Account',
                    LIST: 'List',
                    NEW: 'New',
                    ACCESSLIST: 'Access List'
                },
                ORGANIZASYON: {
                    COLLAPSIBLE: 'Firma',
                    LIST: 'Listele',
                    FIRMAIZINLERI:"Firma İzinleri",
                    NEW: 'Yeni Kayıt',
                    ACCESSLIST: 'Access List'
                },
                SOZLESME: {
                    COLLAPSIBLE: 'Sözleşmeler',
                    LIST: 'Listele',
                    NEW: 'Yeni Kayıt',
                    ACCESSLIST: 'Sozlesme Listesi'
                },
                PERSONEL: {
                    COLLAPSIBLE: 'Personel',
                    LIST: 'Listele',
                    NEW: 'Yeni Kayıt',
                    ACCESSLIST: 'Personel Listesi'
                }, SABLON: {
                    COLLAPSIBLE: 'Şablon',
                    LIST: 'Şablon Listesi',
                    NEW: 'Yeni Şablon',
                    ACCESSLIST: 'Şablon Listesi'
                },
                TANIM: {
                    TANIMLAR: 'Tanımlar',
                    ORGANIZASYON: 'Organizasyon',
                    PERSONEL: 'Personel',
                    ENVANTER: 'Envanter',
                    KURUM: 'Kurumlar',
                    TATILTIP:'Tatil Tipleri',
                    KISIYETKILERI: 'Kişi Yetkileri',
                    SOZLESMETIPLERI: 'Sözleşme Tipleri',
                    CINSIYET:'Cinsiyet',
                    SEKTORLER: 'Sektörler',
                    DEPARTMANLAR: 'Departmanlar',
                    ULKELER: 'Ülke \ Şehir',
                    BANKALAR: 'Bankalar',
                    DILLER: 'Diller',
                    EGITIMDERECESI: 'Eğitim Derecesi',
                    ILETISIMTIPLERI: 'İletişim Tipleri',
                    YAKINLIKTIPLERI:'Yakınlık Tipleri',
                    UYUMLULUKSORULAR: "Uyumluluk Sorular",
                    UYUMLULUKCEVAPLAR: "Uyumluluk Cevaplar",
                    UYUMLULUK: "Uyumluluk",
                    DOKUMANTIPLERI: "Doküman Tipleri",
                    EVRAKTIPLERI: "Evrak Tipleri",
                    SERTIFIKATIPLERI: "Sertifika Tipleri",
                    RESMITATILLER: "Resmi Tatiller",
                    CALISMASAATLERI: "Çalışma Saatleri",
                    ISTENCIKISNEDENI: "İşten Çıkış Nedeni",
                    ISTENAYRILMASEKLI: "İşten Ayrılma Şekli"
                },

            }
        }
    }
};





