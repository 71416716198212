import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
    // Apps & Pages
    {
        id: 'apps',
        type: 'section',
        title: 'Uyum 7/24',
        translate: 'MENU.APPS.SECTION',
        icon: 'package',
        children: [
            {
                id: 'Dashboard',
                title: 'Panel',
                translate: 'MENU.APPS.DASHBOARD',
                type: 'item',
                icon: 'home',
                role:['menuOrtak'],
                url: 'pages/home/home-dashboard'
            },
            {
                id: 'PersonelDashboard',
                title: 'Bilgileri',
                translate: 'MENU.APPS.PERSONELDASHBOARD',
                type: 'item',
                icon: 'home',
                role: ['menuPersonelOrtak'],
                url: 'pages/personel/bilgilerim'
            },
            {
                id: 'organizasyon',
                title: 'Organizasyon',
                translate: 'MENU.APPS.ORGANIZASYON.COLLAPSIBLE',
                type: 'collapsible',
                role: ['menuOrganizasyon'],
                icon: 'briefcase',
                children: [
                    {
                        id: 'list',
                        title: 'Listele',
                        translate: 'MENU.APPS.ORGANIZASYON.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuOrganizasyon'],
                        url: 'pages/organizasyon/organizasyon-liste'
                    },
                    
                    {
                        id: 'edit',
                        title: 'Yeni Organizasyon',
                        translate: 'MENU.APPS.ORGANIZASYON.NEW',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuOrganizasyon'],
                        url: 'pages/organizasyon/organizasyon-form'
                    }
                ]
            },
            {
                id: 'personel',
                title: 'Personel',
                translate: 'MENU.APPS.PERSONEL.COLLAPSIBLE',
                type: 'collapsible',
                icon: 'briefcase',
                role: ['menuPersonelIk'],
                children: [

                    {
                        id: 'acildurumlist',
                        title: 'Acil Durum Listele',
                        translate: 'Acil Durum Listele',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-acil-durum-liste'
                    },
                    {
                        id: 'list',
                        title: 'Personel Listele',
                        translate: 'Personel Listele',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-liste'
                    },
                    {
                        id: 'iletisimBilgileri',
                        title: 'İletişim Bilgileri',
                        translate: 'İletişim Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-iletisim-liste'
                    },
                    {
                        id: 'adresBilgileri',
                        title: 'Adres Bilgileri',
                        translate: 'Adres Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-adres-liste'
                    },
                    {
                        id: 'aileBilgileri',
                        title: 'Aile Bilgileri',
                        translate: 'Aile Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-aile-liste'
                    },
                    {
                        id: 'isDeneyimi',
                        title: 'İş Deneyimi',
                        translate: 'İş Deneyimi',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-isdeneyimi-liste'
                    },
                    {
                        id: 'bankaBilgileri',
                        title: 'Banka Bilgileri',
                        translate: 'Banka Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-banka-liste'
                    },
                    {
                        id: 'kazaBilgileri',
                        title: 'Kaza Bilgileri',
                        translate: 'Kaza Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-kaza-liste'
                    },
                    {
                        id: 'saglikBilgileri',
                        title: 'Sağlık Bilgileri',
                        translate: 'Sağlık Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-sagliksicil-liste'
                    },
                    {
                        id: 'savunmaTalepleri',
                        title: 'Savunma Talepleri',
                        translate: 'Savunma Talepleri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-savunmatalep-liste'
                    },
                    {
                        id: 'sertifikaBilgileri',
                        title: 'Sertifika Bilgileri',
                        translate: 'Sertifika Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-sertifika-liste'
                    },
                    {
                        id: 'yabanciDilBilgileri',
                        title: 'Yabancı Dil Bilgileri',
                        translate: 'Yabancı Dil Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-yabanci-dil-liste'
                    },
                    {
                        id: 'egitimBilgileri',
                        title: 'Eğitim Bilgileri',
                        translate: 'Eğitim Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-egitim-liste'
                    },
                    {
                        id: 'yayinBilgileri',
                        title: 'Yayın Bilgileri',
                        translate: 'Yayın Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-yayin-liste'
                    },
                    {
                        id: 'zimmetBilgileri',
                        title: 'Zimmet Bilgileri',
                        translate: 'Zimmet Bilgileri',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuPersonelIk'],
                        url: 'pages/personel/personel-zimmet-liste'
                    },
                   
                ]
            },
            {
                id: 'envanter',
                title: 'Envanter',
                translate: 'MENU.APPS.ENVANTER.COLLAPSIBLE',
                type: 'collapsible',
                icon: 'book-open',
                role: ['menuEnvanter'],
                children: [
                    {
                        id: 'list',
                        title: 'Envanter Listele',
                        translate: 'MENU.APPS.ENVANTER.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanter'],
                        url: 'pages/envanter/envanter-liste'
                    },
                    {
                        id: 'edit',
                        title: 'Envanter Detayli Listele',
                        translate: 'MENU.APPS.ENVANTER.DETAILLIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanter'],
                        url: 'pages/envanter/envanter-detayli-liste'
                    },
                    {
                        id: 'edit',
                        title: ' Yeni Envanter',
                        translate: 'MENU.APPS.ENVANTER.NEW',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanter'],
                        url: 'pages/envanter/envanter-form'
                    },
                ]
            },
            {
                id: 'sablon',
                title: 'Envanter Şablon',
                translate: 'MENU.APPS.SABLON.COLLAPSIBLE',
                type: 'collapsible',
                icon: 'layers',
                role: ['menuSuperAdmin'],
                children: [
                    {
                        id: 'sablonlistele',
                        title: 'Şablon Listele',
                        translate: 'MENU.APPS.SABLON.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuSuperAdmin'],
                        url: 'pages/envanter/envanter-sablon-liste/true'
                    },
                    {
                        id: 'sablonkaydet',
                        title: ' Şablon Kaydet',
                        translate: 'MENU.APPS.SABLON.NEW',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuSuperAdmin'],
                        url: 'pages/envanter/envanter-sablon/true'
                    },
                ]
            },
            {
                id: 'dokuman',
                title: 'Doküman Set',
                translate: 'MENU.APPS.DOKUMANSET.DOKUMANSET',
                type: 'collapsible',
                icon: 'archive',
                role: ['menuSuperAdmin'],
                children: [
                    {
                        id: 'list',
                        title: 'Doküman Set Listele',
                        translate: 'MENU.APPS.DOKUMANSET.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuSuperAdmin'],
                        url: 'pages/dokuman/dokuman-set-liste'
                    },
                ]
            },
            {
                id: 'egitim',
                title: 'Eğitim Set',
                translate: 'MENU.APPS.EGITIMSET.EGITIMSET',
                type: 'collapsible',
                icon: 'hard-drive',
                role: ['menuSuperAdmin'],
                children: [
                    {
                        id: 'list',
                        title: 'Eğitim Set Listele',
                        translate: 'MENU.APPS.EGITIMSET.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuSuperAdmin'],
                        url: 'pages/egitim/egitim-set-liste'
                    },
                ]
            },
            {
                
                id: 'rapor',
                title: 'Raporlar',
                translate: 'MENU.APPS.RAPOR.LIST',
                type: 'collapsible',
                role: ['menuEnvanterRapor'],
                icon: 'layout',
                children: [
                    {
                        id: 'list',
                        title: 'Raporlar',
                        translate: 'MENU.APPS.RAPOR.LIST',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterRapor'],
                        url: 'pages/rapor/rapor-form'
                    }


                ]
            },
            {
                id: 'tanimlar',
                title: 'Tanımlar',
                translate: 'MENU.APPS.TANIM.TANIMLAR',
                type: 'collapsible',
                icon: 'tool',
                role: ['menuTanim'],
                children: [
                    {
                        id: 'organizasyontanim',
                        title: 'Organizasyon',
                        translate: 'MENU.APPS.TANIM.ORGANIZASYON',
                        type: 'collapsible',
                        icon: 'briefcase',
                        role: ['menuTanim','menuSuperAdmin'],
                        children: [
                            {
                                id: 'tatilgunleritanim',
                                title: 'Resmi Tatiller',
                                translate: 'MENU.APPS.TANIM.RESMITATILLER',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/organizasyon/organizasyon-tatil-gun-liste'
                            },
                            {
                                id: 'calismasaatleritanim',
                                title: 'Çalışma Saatleri',
                                translate: 'MENU.APPS.TANIM.CALISMASAATLERI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/organizasyon/organizasyon-calisma-saatleri-liste'
                            },
                            {
                                id: 'tatiltip',
                                title: 'TatilTip',
                                translate: 'MENU.APPS.TANIM.TATILTIP',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/tatil-tip-liste/3EC15FDF-59A3-47F4-B49D-2012E3A92A90'
                            },
                            {
                                id: 'kurum',
                                title: 'Kurumlar',
                                translate: 'MENU.APPS.TANIM.KURUM',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/kurum-liste/811D5EF0-02EF-445A-9E07-62ABC0F813E9'
                            },

                            {
                                id: 'kisiyetkileritanim',
                                title: 'Kişi Yetkileri',
                                translate: 'MENU.APPS.TANIM.KISIYETKILERI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/kisi-yetki-liste/6EBE84F2-F79F-4A96-B19D-178D07C0E4E0'
                            },
                            {
                                id: 'sektortanim',
                                title: 'Sektörler',
                                translate: 'MENU.APPS.TANIM.SEKTORLER',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/sektor-liste/168440E0-C545-47DA-8A22-DC712C320656'
                            },
                            {
                                id: 'departmantanim',
                                title: 'Departman',
                                translate: 'MENU.APPS.TANIM.DEPARTMANLAR',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/departman-liste/C3ED84FF-C0DA-43E4-A0A2-939894A16F77'
                            },
                            {
                                id: 'ulketanim',
                                title: 'Departman',
                                translate: 'MENU.APPS.TANIM.ULKELER',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim'],
                                url: 'pages/tanim/ulke-liste/1D6F28C8-9013-4750-AADE-B7A98CD2EF8F'
                            },


                        ]
                    },
                    {
                        id: 'personeltanim',
                        title: 'Organizasyon',
                        translate: 'MENU.APPS.TANIM.PERSONEL',
                        type: 'collapsible',
                        role: ['menuPersonelTanim', 'menuSuperAdmin'],
                        icon: 'user',
                        children: [
                          
                            {
                                id: 'bankalartanim',
                                title: 'Bankalar',
                                translate: 'MENU.APPS.TANIM.BANKALAR',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/banka-liste/D1F5105E-FD97-4F74-9B2E-50CBDB5B81F5'
                            },
                            {
                                id: 'dillertanim',
                                title: 'Diller',
                                translate: 'MENU.APPS.TANIM.DILLER',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/dil-liste/990FE80A-D691-4259-9C17-286CB801C8C7'
                            },
                            {
                                id: 'egitimderecesitanim',
                                title: 'Bankalar',
                                translate: 'MENU.APPS.TANIM.EGITIMDERECESI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/egitim-derecesi-liste/0CEE1692-254C-4F02-9163-59E6A3668E02'
                            },
                            {
                                id: 'iletisimtipleritanim',
                                title: 'İletişim Tipleri',
                                translate: 'MENU.APPS.TANIM.ILETISIMTIPLERI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/iletisim-tipleri-liste/830182C9-F22F-4F39-AE83-D54731EECBBC'
                            },
                            {
                                id: 'yakinliktipleritanim',
                                title: 'Yakınlık Tipleri',
                                translate: 'MENU.APPS.TANIM.YAKINLIKTIPLERI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuPersonelTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/yakinlik-tipleri-liste/8CC8FBFB-C65C-4950-8D6C-016A75619EF8'
                            },
                            {
                                id: 'cinsiyetTanim',
                                title: 'Cinsiyet',
                                translate: 'MENU.APPS.TANIM.CINSIYET',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/cinsiyet-liste/754C9E74-6917-4C28-90B8-4D8964580740'
                            },
                            {
                                id: 'sertifikaTipTanim',
                                title: 'Sertifika Tipleri',
                                translate: 'MENU.APPS.TANIM.SERTIFIKATIPLERI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/sertifika-tip-liste/AA9850A0-CDB0-419D-B406-94811BE7869F'
                            },
                            {
                                id: 'istenAyrilmaSekliTanim',
                                title: 'İşten Ayrılma Şekli',
                                translate: 'MENU.APPS.TANIM.ISTENAYRILMASEKLI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/sertifika-tip-liste/1185E28D-262C-4213-A30C-7719AA5B96B8'
                            },
                            {
                                id: 'istenAyrilmaNedeniTanim',
                                title: 'İşten Ayrılma Şekli',
                                translate: 'MENU.APPS.TANIM.ISTENCIKISNEDENI',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/sertifika-tip-liste/9185E28D-262C-4213-A30C-7719AA5B96B8'
                            },
                         

                            
                        ]
                    },
                    {
                        id: 'uyumluluktanim',
                        title: 'Uyumluluk',
                        translate: 'MENU.APPS.TANIM.UYUMLULUK',
                        type: 'collapsible',
                        icon: 'eye',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        children: [
                            {
                                id: 'uyumluluktanim',
                                title: 'Uyumluluk Sorular',
                                translate: 'MENU.APPS.TANIM.UYUMLULUKSORULAR',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/uyumluluk-sorular/488A9850-9FD0-43FA-897C-3E70E40B40FC'
                            },
                            {
                                id: 'uyumluluktanim',
                                title: 'Uyumluluk Cevaplar',
                                translate: 'MENU.APPS.TANIM.UYUMLULUKCEVAPLAR',
                                type: 'item',
                                icon: 'circle',
                                role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                                url: 'pages/tanim/uyumluluk-cevaplar/74DD4299-A2FC-4A29-9D98-7763DC9C459F'
                            }]
                    },
                    {
                        id: 'dokumantipleritanim',
                        title: 'Doküman Tipleri',
                        translate: 'MENU.APPS.TANIM.DOKUMANTIPLERI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuTanim', 'menuSuperAdmin'],
                        url: 'pages/tanim/dokuman-tipleri-liste/59295F31-C27B-45E3-93F5-4EA13F970BBA'
                    },
                    {
                        id: 'evraktipleritanim',
                        title: 'Evrak Tipleri',
                        translate: 'MENU.APPS.TANIM.EVRAKTIPLERI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuTanim', 'menuSuperAdmin'],
                        url: 'pages/tanim/evrak-tipleri-liste/891FE119-57AF-4182-8331-4D5F863326DD'
                    },
                    {
                        id: 'sozlesmetipleri',
                        title: 'Sözleşme Tipleri',
                        translate: 'MENU.APPS.TANIM.SOZLESMETIPLERI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuTanim', 'menuSuperAdmin'],
                        url: 'pages/tanim/sozlesme-tipleri-liste/191FE119-57AF-4182-8331-4D5F863326DD'
                    },

                ]
            },
            {
                id: 'tanim',
                title: 'Envanter Tanımları',
                translate: 'MENU.APPS.ENVANTER.ENVANTERTANIM',
                type: 'collapsible',
                icon: 'list',
                role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                children: [
                    {
                        id: 'verisahibi',
                        title: 'Veri Sahibi',
                        translate: 'MENU.APPS.ENVANTER.VERISAHIBI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-veri-sahibi'
                    },
                    {
                        id: 'kisiselverikategorisi',
                        title: 'Kişisel Veri Kategorisi',
                        translate: 'MENU.APPS.ENVANTER.KISISELVERIKATEGORISI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/kisisel-veri-kategorisi'
                    },
                    {
                        id: 'kisiselveri',
                        title: 'Kişisel Veri',
                        translate: 'MENU.APPS.ENVANTER.KISISELVERI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/kisisel-veri'
                    },
                    {
                        id: 'hukukauygunluksebebi',
                        title: 'Hukuka Uygunluk Sebebi',
                        translate: 'MENU.APPS.ENVANTER.HUKUKAUYGUNLUKSEBEBI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/hukuka-uygunluk-sebepleri'
                    },
                    {
                        id: 'islemeamaci',
                        title: 'İşleme Amacı',
                        translate: 'MENU.APPS.ENVANTER.ISLEMEAMACI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-isleme-amaci'
                    },
                    {
                        id: 'hukukidayanak',
                        title: 'Hukuki Dayanak',
                        translate: 'MENU.APPS.ENVANTER.HUKUKIDAYANAK',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/hukuki-dayanak'
                    },
                    {
                        id: 'idaritedbir',
                        title: 'İdari Tedbir',
                        translate: 'MENU.APPS.ENVANTER.IDARITEDBIR',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-idari-tedbir'
                    },
                    {
                        id: 'tekniktedbir',
                        title: 'Teknik Tedbir',
                        translate: 'MENU.APPS.ENVANTER.TEKNIKTEDBIR',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-teknik-tedbir'
                    },
                    {
                        id: 'aktarilanKisiGrubu',
                        title: 'Aktarılan Kişi Grubu',
                        translate: 'MENU.APPS.ENVANTER.AKTARILANKISIGRUBU',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-aktarilan-kisi-grubu'
                    },
                    {
                        id: 'veriaktarimamaci',
                        title: 'Veri Aktarım Amacı',
                        translate: 'MENU.APPS.ENVANTER.VERIAKTARIMAMACI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-aktarim-amaci'
                    },
                    {
                        id: 'verigiriskanali',
                        title: 'Veri Giriş Kanalı',
                        translate: 'MENU.APPS.ENVANTER.VERIGIRISKANALI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-veri-giris-kanali'
                    },
                    {
                        id: 'veriguvenligi',
                        title: 'Veri Güvenliği',
                        translate: 'MENU.APPS.ENVANTER.VERIGUVENLIGI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-veri-guvenligi'
                    },

                    {
                        id: 'saklamaperiyodu',
                        title: 'Saklama Periyodu',
                        translate: 'MENU.APPS.ENVANTER.SAKLAMAPERIYODU',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-saklama-periyodu'
                    },
                    {
                        id: 'saklamayeri',
                        title: 'Saklama Yeri',
                        translate: 'MENU.APPS.ENVANTER.SAKLAMAYERI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-saklama-yeri'
                    },
                    {
                        id: 'imhayontemi',
                        title: 'İmha Yöntemi',
                        translate: 'MENU.APPS.ENVANTER.IMHAYONTEMI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-imha-yontemi'
                    },
                    {
                        id: 'imhasebebi',
                        title: 'İmha Sebebi',
                        translate: 'MENU.APPS.ENVANTER.IMHASEBEBI',
                        type: 'item',
                        icon: 'circle',
                        role: ['menuEnvanterTanim', 'menuSuperAdmin'],
                        url: 'pages/envanter/tanim-imha-sebebi'
                    },


                ]
            },
            {
                id: 'dokumantipleritanim',
                title: 'Uyumluluk İzleme',
                translate: 'MENU.APPS.UYUMLULUKIZLEME',
                type: 'item',
                icon: 'eye',
                role: ['menuEnvanter'],
                url: 'pages/envanter/uyumluluk-izleme'
            },
            {
                id: 'firmaIzinleri',
                title: 'Listele',
                translate: 'MENU.APPS.ORGANIZASYON.FIRMAIZINLERI',
                type: 'item',
                icon: 'circle',
                role: ['menuSuperAdmin'],
                url: 'pages/organizasyon/organizasyon-sifre'
            },
            {
                id: 'evraklistesi',
                title: 'Evrak Listesi',
                translate: 'MENU.APPS.DOCUMENTLIST',
                type: 'item',
                icon: 'circle',
                role: ['menuPersonelIk'],
                url: 'pages/evrak/evrak-liste/891fe119-57af-4182-8331-4d5f863326dd'
            },
            {
                id: 'sozlesmelistesi',
                title: 'Sözleşme',
                translate: 'MENU.APPS.SOZLESME.COLLAPSIBLE',
                type: 'item',
                icon: 'circle',
                role: ['menuPersonelIk'],
                url: 'pages/evrak/sozlesme-liste/191fe119-57af-4182-8331-4d5f863326dd'
            },
            {
                id: 'activitylog',
                title: 'Listele',
                translate: 'MENU.APPS.ACTIVITYLOG',
                type: 'item',
                icon: 'circle',
                role: ['menuLog'],
                url: 'pages/activitylog/log-liste'
            },
            {
                id: 'Logout',
                title: 'Logout',
                translate: 'MENU.APPS.LOGOUT',
                type: 'item',
                icon: 'log-out',
                role: ['menuOrtak'],
                url: 'authentication/authentication/login'
            },
        ]
    }
];
