import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class CoreSharedDataService {

  private data: any;
  private type: any;
  private startDate: any;
  private endDate: any;


  private job: any;
  private jobMedia: any;
  private openDataLayer: any;
  private openHistoricLayer: any;
  private openLastSeenLayer: any;
  private openPlaceLayer: any;


  private isDownloadInProgress: any;
  private isUploadInProgress: any;

  sentinelHub: any;
  sentinelHubUpdated = new EventEmitter<any>();

  addProduct(product) {
    this.sentinelHub = product;
    this.sentinelHubUpdated.emit(this.sentinelHub);
  }

  explore3DUpdate: any;
  explore3DUpdated = new EventEmitter<any>();

  update3DLayer(searchFilter) {
    this.explore3DUpdate = searchFilter;
    this.explore3DUpdated.emit(this.explore3DUpdate);
  }

  aerialImagery: any;
  aerialImageryUpdated = new EventEmitter<any>();

  addProductAerialImagery(productAerialImagery) {
    this.aerialImagery = productAerialImagery;
    this.aerialImageryUpdated.emit(this.aerialImagery);
  }

  groundImagery: any;
  groundImageryUpdated = new EventEmitter<any>();

  addProductGroundImagery(productGroundImagery) {
    this.groundImagery = productGroundImagery;
    this.groundImageryUpdated.emit(this.groundImagery);
  }

  private returnModel: any = {
    data: null,
    type: null,
    startDate: null,
    endDate: null,
    openDataLayer: null,
    openHistoricLayer: null,
    openLastSeenLayer: null,
    openPlaceLayer: null
  };

  private returnExplore3DModel: any = {
    job: null,
    jobMedia: null,
    type: null,
    startDate: null,
    endDate: null,
    openDataLayer: null,
    openHistoricLayer: null,
    openLastSeenLayer: null,
    openPlaceLayer: null
  };

  private modelProgressCheck: any = { isDownloadInProgress: false, isUploadInProgress: false };

  constructor() {
    this.data = null;
    this.type = null;
    this.startDate = null;
    this.endDate = null;
    this.job = null;
    this.jobMedia = null;
    this.openDataLayer = false;
    this.openHistoricLayer = false;
    this.openLastSeenLayer = false;
    this.openPlaceLayer = false;
  }

  setData(value, type, startDate, endDate) {
    this.data = value;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;

    this.returnModel.data = this.data;
    this.returnModel.type = this.type;
    this.returnModel.startDate = this.startDate;
    this.returnModel.endDate = this.endDate;
  }

  setDataForExplore3D(job,
    jobMedia,
    type,
    startDate,
    endDate,
    openDataLayer,
    openHistoricLayer,
    openLastSeenLayer,
    openPlaceLayer) {
    this.job = job;
    this.jobMedia = jobMedia;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;
    this.openDataLayer = openDataLayer;
    this.openHistoricLayer = openHistoricLayer;
    this.openLastSeenLayer = openLastSeenLayer;
    this.openPlaceLayer = openPlaceLayer;

    this.returnExplore3DModel.job = this.job;
    this.returnExplore3DModel.jobMedia = this.jobMedia;
    this.returnExplore3DModel.type = this.type;
    this.returnExplore3DModel.startDate = this.startDate;
    this.returnExplore3DModel.endDate = this.endDate;
    this.returnExplore3DModel.openDataLayer = this.openDataLayer;
    this.returnExplore3DModel.openHistoricLayer = this.openHistoricLayer;
    this.returnExplore3DModel.openLastSeenLayer = this.openLastSeenLayer;
    this.returnExplore3DModel.openPlaceLayer = this.openPlaceLayer;
  }

  setDataProgressCheck(isDownloadInProgress, isUploadInProgress) {
    if (isDownloadInProgress != null) {
      this.isDownloadInProgress = isDownloadInProgress;
      this.modelProgressCheck.isDownloadInProgress = this.isDownloadInProgress;
    }
    if (isUploadInProgress != null) {
      this.isUploadInProgress = isUploadInProgress;
      this.modelProgressCheck.isUploadInProgress = this.isUploadInProgress;
    }
  }

  getDataProgressCheck() {
    return this.modelProgressCheck;
  }
  //setDataFileListUpload(fileList, fileListUploadMediaPermissionModel) {
  //  this.uploadModel = { fileListUpload: null, fileListUploadMediaPermissionModel: null };
  //  this.uploadModel.fileListUpload = fileList;
  //  this.uploadModel.fileListUploadMediaPermissionModel = fileListUploadMediaPermissionModel;
  //}

  //setDataFileListUploadNull() {
  //  this.uploadModel = { fileListUpload : null , fileListUploadMediaPermissionModel : null };
  //}

  getDataForExplopre3D() {
    return this.returnExplore3DModel;
  }

  getData() {
    return this.returnModel;
  }

  //getDataFileListUpload() {
  //  return this.uploadModel;
  //}


}
