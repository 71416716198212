export const localeOrtak = {
    lang: 'en',
    data: {
        Download:"Download",
        AktifMi: "Active / Passsive",
        Listele: "Search Result",
        AramaKriterleri: "Search Criteria",
        YeniKayit: "New Record",
        Duzenle: "Edit",
        Kaydet: "Save",
        Sil: "Delete",
        Ara: "Search",
        Temizle: "Reset",
        Vazgec: "Cancel",
        Liste: "List",
        Bekle: "Please Wait",
        Uyari: "Warning",
        KayitSilmeOnaymesaji: "The deletion cannot be undone, are you sure you want to continue?",
        Onayla: "Confirm",
        Bilgi: "Information",
        IslemGerceklesti: "The transaction has taken place.",
        Durum: "Status",
        REQ: "Required",
        TelefonFormat: " The phone must have a correct format +00 (000) 000-0000",
        NO_AUTHENTICATION: "Authentication is required",
        OK: "Success",
        FAIL: "Problem Occured",
        SESSION_LOST: "Session Lost",
        DATA_ALREADY_EXIST: "Data Alreadt Exist",
        WRONG_DATA_TYPE: "Wrong Data Type",
        TOKEN_NOT_VALID: "Token Not Valid",
        LOGIN: "Login",
        NO_AUTHORIZATION: "Authorization is required",
        NO_AUTHORIZATION_FOR_THIS_USER: "Authorization is required for user",
        NO_VALID_RECENT_PASSWORD: "Your recent password is not valid",
        SadeceHarf: "Just enter letters",
        SadeceRakam: "Enter numbers only",
        SifrePattern: "Please enter a password at least one capital letter, at least one number and at least 8 character",
        Zorunlu: "Required",
        GecerliTarih: "Enter valid date",
        GecerliTarihFormat: "The date must have the following format: MM/dd/yyyy",
        GecerliEmail: "Enter valid email",
        PasifKayitlar: "Show Passive Records",
        TarihRange: "End Date must be greater than the start date",
        RaporAl: "Report",
        Yukle: "Upload",
        ResimSil: "Delete",
        TumSektorler: "Al Sector",
        SektorSeciniz: "Select Sector",
        Evet: "Yes",
        Hayir: "No",
        CikisMesaji: "You are about to log out of the system, do you want to continue?",
        CikisYap: "Log out !",
        OturumUzatmaMesajUst: "Your session is about to expire!",
        OturumUzatmaMesajAlt: "If you wish to continue actively, please click the 'Continue' button to extend your session.",
        OturumDevam: "Continue",
        OturumCikis: "Close",
        OturumMesaj: "Time Remaining",
        OturumKapatiliyor: "Logging out",
        OturumZamanAsimiSayac: "You will time out in seconds. You will be logged out.",
        NavBarCikis: "Logout",
        NavBarSifreDegistir: "Change Password",
        LinkOlustur:"Generate Link"
    }
}

export const localeMenu = {
    lang: 'en',
    data: {
        MENU: {
            APPS: {
                SECTION: 'Apps & Pages',
                LOGOUT: 'Logout',
                DASHBOARD: 'Dashboard',
                UYUMLULUKIZLEME: 'Compliance Monitoring',
                ACTIVITYLOG: "Activity Log",
                DOCUMENTLIST: "Document List",
                ENVANTER:
                {
                    ENVANTERTANIM: 'Definations of Inventory',
                    VERISAHIBI: 'Data Subject',
                    KISISELVERI: 'Personal Data Processe',
                    KISISELVERIKATEGORISI: 'Category of Personal Data',
                    ISLEYENBIRIM: 'İşleyen birim',
                    ISLEMEAMACI: "Purpose of Processing",
                    HUKUKIDAYANAK: 'Legal Basis',
                    VERIGIRISKANALI: "Data Entry Channelı",
                    TEKNIKTEDBIR: "Technical Measure",
                    IDARITEDBIR: "Administrative Measurer",
                    SAKLAMAPERIYODU: "Period of Storage",
                    SAKLAMAYERI: "Location of Storage",
                    AKTARILANKISIGRUBU: 'Recipients',
                    VERIAKTARIMAMACI: "Purpose of Transfer",
                    HUKUKAUYGUNLUKSEBEBI: "Reason for Compliance with Law",
                    DETAILLIST: "Detail List",
                    VERIGUVENLIGI: "Security Measures",
                    IMHASEBEBI: "Reason of Destruction",
                    IMHAYONTEMI: "Method of Destructioni",
                    LIST: 'Inventory List',
                    NEW: 'New Inventory',
                    ACCESSLIST: 'List',
                    COLLAPSIBLE:"Inventory"
                },
                DOKUMANSET: {
                    DOKUMANSET: 'Document Set',
                    LIST: 'List',
                    NEW: 'New',
                    ACCESSLIST: 'Document Set List'
                },
                EGITIMSET: {
                    EGITIMSET: 'Training Set',
                    LIST: 'List',
                    NEW: 'New',
                },
                RAPOR: {
                    LIST: 'Reports',
                },
                ACCOUNT: {
                    COLLAPSIBLE: 'Account',
                    LIST: 'List',
                    FIRMAIZINLERI: "Firma İzinleri",
                    NEW: 'New',
                    ACCESSLIST: 'Access List'
                },
                ORGANIZASYON: {
                    COLLAPSIBLE: 'Organization',
                    LIST: 'List',
                    NEW: 'New',
                    FIRMAIZINLERI:'Firm Access Permissions',
                    ACCESSLIST: 'Access List'
                },
                PERSONEL: {
                    COLLAPSIBLE: 'Employee',
                    LIST: 'List',
                    NEW: 'New',
                    ACCESSLIST: 'Employee List'
                },
                SABLON: {
                    COLLAPSIBLE: 'Template',
                    LIST: 'Template List',
                    NEW: 'New Template',
                    ACCESSLIST: 'Şablon Listesi'
                },
                TANIM: {
                    TANIMLAR: 'Definations'
                },
            }
        }
    }
};





