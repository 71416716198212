<div class="customizer-content" [perfectScrollbar]>
    <!-- Customizer header -->
    <div class="customizer-header px-2 pt-1 pb-0 position-relative">
        <h4 class="mb-0">Data Progress</h4>
        <p class="m-0">Download Queue Progress</p>

        <a class="customizer-close" (click)="toggleSidebar('uploadCustomizer')"><span [data-feather]="'x'"></span></a>
    </div>

    <hr />


    <div class="container container-md">





   
        
            <div class="col-sm-12 mt-3">

                <div class="card card-default">
                    <div class="card-body">
   
                        <div>
                            
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progress + '%' }">
                                    <strong>{{totalFileSizeDownloadedWithUnit}} of {{totalFileSizeDownloadWithUnit}} </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    </div>